import React from "react"

export default ({ title, children, ...other }) => {
    const colClassName = title ? 'col md:w-1/2' : 'col'

    return (
        <div {...other}>
            <div className="box my-md clearfix bg-gray-lightest px-xs py-md sm:px-sm md:flex">
                {title &&
                    <div className={colClassName}>
                        <h3 className="mt-0">{title}</h3>
                    </div>
                }
                <div className={colClassName}>
                    {children}
                </div>
            </div>
        </div>
    )
}
